import React, { useEffect, useRef } from 'react';

const highlightText = (text, query) => {
  const parts = text.split(new RegExp(`(${query})`, 'gi'));
  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? <span key={index} className="highlight">{part}</span> : part
  );
};

const TypeaheadList = ({ suggestions, query, selectedIndex, onSelect }) => {
  const listRef = useRef(null);

  useEffect(() => {
    if (selectedIndex >= 0 && listRef.current) {
      const selectedItem = listRef.current.children[selectedIndex];
      if (selectedItem) {
        selectedItem.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [selectedIndex]);

  return (
    <ul className="typeahead-list" id="typeahead-list" role="listbox" ref={listRef}>
      {suggestions.map((suggestion, index) => (
        <li>
          <a
            key={suggestion.id}
            id={`suggestion-${index}`}
            role="option"
            aria-selected={index === selectedIndex}
            className={`typeahead-item ${index === selectedIndex ? 'selected' : ''}`}
            href={() => onSelect(suggestion)}
            onClick={() => onSelect(suggestion)}
          >
            <span className="identifier">{suggestion.identifier}</span>
            <span className="label">{highlightText(suggestion.label, query)}</span>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default TypeaheadList;
