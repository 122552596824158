// src/utils/airtable.js

import axios from 'axios';

const AIRTABLE_API_URL = 'https://api.airtable.com/v0';

export const fetchSuggestions = async (apiKey, baseId, table, query, labelFieldId, labelFieldName, identifierField) => {
  try {
    const filterByFormula = `FIND(LOWER("${query}"), LOWER({${labelFieldId}}))`;
    const response = await axios.get(`${AIRTABLE_API_URL}/${baseId}/${table}`, {
      headers: { Authorization: `Bearer ${apiKey}` },
      params: {
        filterByFormula,
        fields: [labelFieldId, labelFieldName, identifierField]
      }
    });

    console.log('API Response:', response.data);

    if (response.status !== 200) {
      console.error('Error fetching suggestions:', response.status, response.statusText);
      return [];
    }

    const records = response.data.records;
    console.log('Fetched records:', records);

    const matchingRecords = records.filter(record => {
      const labelsById = record.fields[labelFieldId];
      const labelsByName = record.fields[labelFieldName];
      console.log('Checking record:', record);
      console.log('Labels by ID:', labelsById);
      console.log('Labels by Name:', labelsByName);

      if (!labelsById && !labelsByName) {
        console.log('No labels found for record:', record.id);
        return false;
      }

      const labels = labelsById || labelsByName;
      return labels.split(',').some(label => label.trim().toLowerCase().includes(query.toLowerCase()));
    });

    console.log('Matching records:', matchingRecords);

    const suggestions = matchingRecords.map(record => ({
      id: record.id,
      label: record.fields[labelFieldId] || record.fields[labelFieldName],
      identifier: record.fields[identifierField]
    }));

    // Sort suggestions alphabetically by label
    suggestions.sort((a, b) => a.label.localeCompare(b.label));

    console.log('Filtered suggestions:', suggestions);
    return suggestions;
  } catch (error) {
    console.error('Error fetching suggestions:', error.response ? error.response.data : error.message);
    return [];
  }
};
